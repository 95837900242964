import { lazyRetry } from "@advocatebase/web-components";
import React from "react";

const OrganisationSelectPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "OrganisationSelectPage" */ "../pages/login/OrganisationSelectPage"
      ),
    "OrganisationSelectPage"
  )
);
const AccountPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AccountPage" */ "../pages/profile/AccountPage"
      ),
    "AccountPage"
  )
);
const ProfilePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ProfilePage" */ "../pages/profile/ProfilePage"
      ),
    "ProfilePage"
  )
);
const SecurityPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SecurityPage" */ "../pages/profile/SecurityPage"
      ),
    "SecurityPage"
  )
);
const SettingsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "SettingsPage" */ "../pages/profile/SettingsPage"
      ),
    "SettingsPage"
  )
);

export const ROUTE_SLUGS = {
  ACCOUNT: "/account",
  PROFILE: "/account/profile",
  SECURITY: "/account/security",
  SETTINGS: "/account/settings/*",
  ORGANISATION_SELECT: "/organisationselect",
};

export const HOME_SLUG = ROUTE_SLUGS.ORGANISATION_SELECT;

export const generateRoutes = ({ organisationHomeSlug, adminPortalHomeSlug }) =>
  [
    {
      path: ROUTE_SLUGS.ACCOUNT,
      element: AccountPage,
    },
    {
      path: ROUTE_SLUGS.PROFILE,
      element: ProfilePage,
    },
    {
      path: ROUTE_SLUGS.SECURITY,
      element: SecurityPage,
    },
    {
      path: ROUTE_SLUGS.SETTINGS,
      element: SettingsPage,
    },
    {
      // Note, there is a similar AutoChangeOrgPage in basicRoutes.js
      path: ROUTE_SLUGS.ORGANISATION_SELECT,
      element: OrganisationSelectPage,
      options: {
        organisationHomeSlug,
        adminPortalHomeSlug,
      },
    },
  ].filter(Boolean);
