import { lazyRetry } from "@advocatebase/web-components";
import React from "react";

const EditOrgPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "EditOrgPage" */ "../pages/organisations/EditOrgPage"
      ),
    "EditOrgPage"
  )
);
const ViewAllOrgsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllOrgsPage" */ "../pages/organisations/ViewAllOrgsPage"
      ),
    "ViewAllOrgsPage"
  )
);
const ViewOrgPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewOrgPage" */ "../pages/organisations/ViewOrgPage"
      ),
    "ViewOrgPage"
  )
);
const AdminDashboardPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "AdminDashboardPage" */ "../pages/advocatebaseAdmin/AdminDashboardPage"
      ),
    "AdminDashboardPage"
  )
);
const ViewAllUsersPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllUsersPage" */ "../pages/advocatebaseAdmin/ViewAllUsersPage"
      ),
    "ViewAllUsersPage"
  )
);
const ViewUserPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewUserPage" */ "../pages/advocatebaseAdmin/ViewUserPage"
      ),
    "ViewUserPage"
  )
);

export const ROUTE_SLUGS = {
  VIEW_ALL_ORGANISATIONS: "/organisations",
  VIEW_ORGANISATION: "/organisations/:id",
  NEW_ORGANISATION: "/organisations/new",
  EDIT_ORGANISATION: "/organisations/:id/edit",
  VIEW_ALL_USERS: "/users",
  VIEW_USER: "/users/:id",
};

export const HOME_SLUG = "/home";

export const generateRoutes = () => [
  {
    path: HOME_SLUG,
    element: AdminDashboardPage,
  },
  {
    path: ROUTE_SLUGS.VIEW_ALL_ORGANISATIONS,
    element: ViewAllOrgsPage,
  },
  {
    path: ROUTE_SLUGS.VIEW_ORGANISATION,
    element: ViewOrgPage,
  },
  {
    path: ROUTE_SLUGS.NEW_ORGANISATION,
    element: EditOrgPage,
    options: { isNew: true },
  },
  {
    path: ROUTE_SLUGS.EDIT_ORGANISATION,
    element: EditOrgPage,
  },
  {
    path: ROUTE_SLUGS.VIEW_ALL_USERS,
    element: ViewAllUsersPage,
  },
  {
    path: ROUTE_SLUGS.VIEW_USER,
    element: ViewUserPage,
  },
];
