import { IntegrationSyncStatus } from "../services/integrationApi";

export enum AuthStatus {
  Pending = "pending",
  Authorised = "authorised",
  Unauthorised = "unauthorised",
}

export enum ViewMode {
  Advocatebase = "advocatebase",
  Organisation = "organisation",
}

// Note - these three types of roles are idential to their counterparts on the server
export enum AdvocateBaseRole {
  Admin = "admin",
  User = "user",
}

export enum OrganisationRole {
  Admin = "admin",
  Manager = "manager",
  User = "user",
}

export enum TeamRole {
  Admin = "admin",
  Manager = "manager",
  User = "user",
  Assigned = "assigned",
}

export interface TokenPayload {
  id: string;
  jwt_version: number;
  role: AdvocateBaseRole;
  organisations: {
    id: string;
    role: OrganisationRole;
    personId: string | null;
    teams: {
      id: string;
      role: TeamRole;
    }[];
    qualifications: string[];
  }[];
  iat: number;
  exp: number;
}

export type AuthState =
  | {
      status: AuthStatus.Authorised;
      token: string;
      tokenPayload: TokenPayload;
      activeOrgId: string | null;
      viewMode: ViewMode | null;
    }
  | {
      status: AuthStatus.Pending | AuthStatus.Unauthorised;
    };

export interface WebSocketIntegrationsState {
  syncStatus: IntegrationSyncStatus;
  scanPercentage?: number | null;
}

type WebSocketState = {
  integrations: Record<string, WebSocketIntegrationsState>;
};

export interface State {
  auth: AuthState;
  api?: any;
  websocket: WebSocketState;
}
