import { Button, Typography, Paper } from "@mui/material";
import { useAbNavigator } from "@advocatebase/web-components";
import BasePage from "./BasePage";

const NotFoundPage = ({ homePageSlug = "/" }) => {
  const nav = useAbNavigator();

  return (
    <BasePage title="404 - Page Not Found" scrollY>
      <Paper square variant="page-element-notop" sx={{ p: 2 }}>
        <Typography variant="body1" paragraph>
          The page you are looking for does not exist. Click below to return to
          the homepage.
        </Typography>
        <Button variant="contained" onClick={() => nav.to(homePageSlug)}>
          Home
        </Button>
      </Paper>
    </BasePage>
  );
};

export default NotFoundPage;
