import { Menu, Box, Typography } from "@mui/material";
import {
  AbDivider,
  AbVirtualisedList,
  useAbSnackbar,
} from "@advocatebase/web-components";
import _ from "lodash";
import {
  Notification,
  useApiGetNotificationList,
  useApiUpdateNotification,
} from "../../services/notificationsApi";
import { logger } from "@advocatebase/toolbox";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import NotificationCard from "../controls/data-display/card/NotificationCard";

interface NotificationsDialogProps {
  onClose: () => void;
  open: boolean;
  anchorEl?: Element | (() => Element) | null;
}

const NotificationsDialog = ({
  onClose,
  open,
  anchorEl,
}: NotificationsDialogProps) => {
  const notificationList = useApiGetNotificationList();
  const updateNotification = useApiUpdateNotification();
  const snackbar = useAbSnackbar();

  const handleMarkAsRead = async (
    notification: Notification,
    hasBeenRead: boolean,
    suppressError = false
  ) => {
    if (notification.hasBeenRead !== hasBeenRead) {
      try {
        const response = await updateNotification({
          id: notification.id,
          updateObject: { hasBeenRead },
        });

        if ("error" in response && response.error) {
          throw new Error(
            "message" in response.error ? response.error.message : undefined
          );
        }
      } catch (e) {
        logger.debug(e);
        if (!suppressError) {
          snackbar.error(
            `Notification not marked as ${
              hasBeenRead ? "read" : "unread"
            } (due to network problems).`
          );
        }
      }
    }
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      MenuListProps={{
        sx: {
          height: "800px",
          maxHeight: "80vh",
          maxWidth: "80vw",
          width: "300px",
          p: 0,
          display: "flex",
          flexDirection: "column",
        },
      }}
      slotProps={{
        paper: {
          sx: { backgroundColor: (theme) => theme.palette.grey[100] },
        },
      }}
    >
      <Typography variant="h2" sx={{ m: 1.2, textAlign: "center" }}>
        Notifications
      </Typography>
      <AbDivider orientation="horizontal" />
      <Box sx={{ flexGrow: 1 }}>
        {notificationList && (
          <AbVirtualisedList
            items={notificationList}
            getElement={(element: any) => (
              <NotificationCard
                {...element}
                onClick={() => {
                  handleMarkAsRead(element, true, true);
                  onClose();
                }}
                markAsRead={(hasBeenRead) =>
                  handleMarkAsRead(element, hasBeenRead)
                }
              />
            )}
            getSizingElement={() => <NotificationCard />}
            spacing={0.25}
            emptyMessage={
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  p: 4,
                }}
              >
                <Box sx={{ fontSize: "6rem", lineHeight: 0, mb: 1 }}>
                  <WbSunnyIcon fontSize="inherit" color="primary" />
                </Box>
                <Typography
                  variant="h4"
                  sx={{
                    textAlign: "center",
                    mb: 1,
                    color: (theme) => theme.palette.text.secondary,
                  }}
                >
                  No notifications at the moment
                </Typography>
              </Box>
            }
            disableTypography={true}
          />
        )}
      </Box>
    </Menu>
  );
};

export default NotificationsDialog;
