import { Button, Typography, Paper } from "@mui/material";
import { useAbNavigator } from "@advocatebase/web-components";
import BasePage from "./BasePage";

const NoConnectionPage = () => {
  const nav = useAbNavigator();

  return (
    <BasePage title="Internet Down" scrollY>
      <Paper square variant="page-element-notop" sx={{ p: 2 }}>
        <Typography variant="body1" paragraph>
          We cannot connect to the internet at the moment. Check your connection
          then refresh this page to try again.
        </Typography>
        <Button variant="contained" onClick={() => nav.to(0)}>
          Refresh Page
        </Button>
      </Paper>
    </BasePage>
  );
};

export default NoConnectionPage;
