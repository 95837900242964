import { lazyRetry } from "@advocatebase/web-components";
import React from "react";
import { Navigate } from "react-router-dom";

const ViewTeamPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewTeamPage" */ "../pages/teams/ViewTeamPage"
      ),
    "ViewTeamPage"
  )
);
const ViewPersonPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewPersonPage" */ "../pages/people/ViewPersonPage"
      ),
    "ViewPersonPage"
  )
);
const ViewAllPeoplePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllPeoplePage" */ "../pages/people/ViewAllPeoplePage"
      ),
    "ViewAllPeoplePage"
  )
);
const ViewAllTeamsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllTeamsPage" */ "../pages/teams/ViewAllTeamsPage"
      ),
    "ViewAllTeamsPage"
  )
);
const ViewAllOrgUsersPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllOrgUsersPage" */ "../pages/admin/ViewAllOrgUsersPage"
      ),
    "ViewAllOrgUsersPage"
  )
);
const ViewAllEventsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllEventsPage" */ "../pages/events/ViewAllEventsPage"
      ),
    "ViewAllEventsPage"
  )
);
const ViewEventPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewEventPage" */ "../pages/events/ViewEventPage"
      ),
    "ViewEventPage"
  )
);
const ViewOrgUserPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewOrgUserPage" */ "../pages/admin/ViewOrgUserPage"
      ),
    "ViewOrgUserPage"
  )
);
const AdminPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "AdminPage" */ "../pages/admin/AdminPage"),
    "AdminPage"
  )
);
const PageEditorPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "PageEditorPage" */ "../pages/pageEditor/PageEditorPage"
      ),
    "PageEditorPage"
  )
);
const ViewAllQualsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllQualsPage" */ "../pages/quals/ViewAllQualsPage"
      ),
    "ViewAllQualsPage"
  )
);
const ViewQualPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewQualPage" */ "../pages/quals/ViewQualPage"
      ),
    "ViewQualPage"
  )
);
const ViewAllConsentRecordsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllConsentRecordsPage" */ "../pages/consents/ViewAllConsentRecordsPage"
      ),
    "ViewAllConsentRecordsPage"
  )
);
const ViewConsentRecordPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewConsentRecordPage" */ "../pages/consents/ViewConsentRecordPage"
      ),
    "ViewConsentRecordPage"
  )
);
const ViewAllConsentDefinitionsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllConsentDefinitionsPage" */ "../pages/consents/ViewAllConsentDefinitionsPage"
      ),
    "ViewAllConsentDefinitionsPage"
  )
);
const NewConsentDefinitionPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "NewConsentDefinitionPage" */ "../pages/consents/NewConsentDefinitionPage"
      ),
    "NewConsentDefinitionPage"
  )
);
const ViewConsentDefinitionPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewConsentDefinitionPage" */ "../pages/consents/ViewConsentDefinitionPage"
      ),
    "ViewConsentDefinitionPage"
  )
);
const ConsentsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ConsentsPage" */ "../pages/admin/ConsentsPage"
      ),
    "ConsentsPage"
  )
);

// Training Sessions
const TrainingSessionsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "TrainingSessionsPage" */ "../pages/trainingSessions/TrainingSessionsPage"
      ),
    "TrainingSessionsPage"
  )
);
const ViewAllTrainingSessionDefinitionsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllTrainingSessionDefinitionsPage" */ "../pages/trainingSessions/definitions/ViewAllTrainingSessionDefinitionsPage"
      ),
    "ViewAllTrainingSessionDefinitionsPage"
  )
);
const ViewTrainingSessionDefinitionPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewTrainingSessionDefinitionPage" */ "../pages/trainingSessions/definitions/ViewTrainingSessionDefinitionPage"
      ),
    "ViewTrainingSessionDefinitionPage"
  )
);
const ViewAllTrainingSessionInstancesPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllTrainingSessionInstancesPage" */ "../pages/trainingSessions/instances/ViewAllTrainingSessionInstancesPage"
      ),
    "ViewAllTrainingSessionInstancesPage"
  )
);
const ViewTrainingSessionInstancePage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewTrainingSessionInstancePage" */ "../pages/trainingSessions/instances/ViewTrainingSessionInstancePage"
      ),
    "ViewTrainingSessionInstancePage"
  )
);

const ImportPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "ImportPage" */ "../pages/import/ImportPage"),
    "ImportPage"
  )
);
const ViewAllIntegrationsPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewAllIntegrationsPage" */ "../pages/admin/ViewAllIntegrationsPage"
      ),
    "ViewAllIntegrationsPage"
  )
);
const ViewIntegrationPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ViewIntegrationPage" */ "../pages/admin/ViewIntegrationPage"
      ),
    "ViewIntegrationPage"
  )
);

export const ROUTE_SLUGS = {
  DASHBOARD: "/",
  RESOURCES: "/resources",
};

export const HOME_SLUG = ROUTE_SLUGS.DASHBOARD;

export const generateRoutes = (auth) =>
  [
    auth.showAdminSection && {
      path: "/admin",
      element: AdminPage,
    },
    auth.showUsers && [
      {
        path: "/admin/users",
        element: ViewAllOrgUsersPage,
      },
      {
        path: "/admin/users/:personId",
        element: ViewOrgUserPage,
      },
    ],
    auth.showIntegrations && [
      {
        path: "/admin/integrations",
        element: ViewAllIntegrationsPage,
      },
      {
        path: "/admin/integrations/:integrationId",
        element: ViewIntegrationPage,
      },
    ],
    auth.showCustomPageEditor && {
      path: "/admin/pages",
      element: PageEditorPage,
    },
    auth.showQuals && [
      {
        path: "/admin/quals",
        element: ViewAllQualsPage,
      },
      {
        path: "/admin/quals/:id",
        element: ViewQualPage,
      },
    ],
    // Trianing Sessions
    auth.showTrainingSessions && {
      path: "/admin/training",
      element: TrainingSessionsPage,
    },
    auth.showTrainingSessionInstances && [
      {
        path: "/admin/training/instances",
        element: ViewAllTrainingSessionInstancesPage,
      },
      {
        path: "/admin/training/instances/:id",
        element: ViewTrainingSessionInstancePage,
      },
    ],
    auth.showTrainingSessionDefinitions && [
      {
        path: "/admin/training/definitions",
        element: ViewAllTrainingSessionDefinitionsPage,
      },
      {
        path: "/admin/training/definitions/:id",
        element: ViewTrainingSessionDefinitionPage,
      },
    ],
    // Other
    auth.showConsents && {
      path: "/admin/consents",
      element: ConsentsPage,
    },
    auth.showImportPeople && {
      path: "/admin/import",
      element: ImportPage,
    },
    auth.showConsentRecords && [
      {
        path: "/admin/consents/records",
        element: ViewAllConsentRecordsPage,
      },
      {
        path: "/admin/consents/records/:id",
        element: ViewConsentRecordPage,
      },
    ],
    auth.showConsentDefinitions && [
      {
        path: "/admin/consents/definitions",
        element: ViewAllConsentDefinitionsPage,
      },
      {
        path: "/admin/consents/definitions/new",
        element: NewConsentDefinitionPage,
      },
      {
        path: "/admin/consents/definitions/:id",
        element: ViewConsentDefinitionPage,
      },
    ],
    auth.showEvents &&
      [
        {
          path: "/events",
          element: ViewAllEventsPage,
        },
        {
          path: "/events/:id",
          element: ViewEventPage,
        },
      ].flat(),
    auth.showTeams && [
      {
        path: "/teams",
        element: ViewAllTeamsPage,
      },
    ],
    !auth.showTeams && {
      path: "/teams",
      element: Navigate,
      options: { to: HOME_SLUG },
    },
    // Note - if the specific team is not accessable, the ViewTeamPage will redirect to /notfound
    {
      path: "/teams/:id",
      element: ViewTeamPage,
    },
    auth.showPeople && [
      {
        path: "/people",
        element: ViewAllPeoplePage,
      },
      {
        path: "/people/:id",
        element: ViewPersonPage,
      },
    ],
  ]
    .flat()
    .filter(Boolean);
