import { AbContentRenderer } from "@advocatebase/web-components";
import { SxProps } from "@mui/material";
import DirectoryRenderer from "./DirectoryRenderer";

const CustomisedContentRenderer = ({
  content,
  sx,
}: {
  content: string;
  sx?: SxProps;
}) => (
  <AbContentRenderer
    content={content}
    sx={sx}
    customRenderers={{ directory: DirectoryRenderer }}
  />
);

export default CustomisedContentRenderer;
