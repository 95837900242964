import { useSelector } from "react-redux";

/**
 * This wrapper function adds the activeOrgId to the query.
 * @param {API Query} useGenericQuery The API query to wrap
 * @returns {API Query}
 */
export const wrapQuery = (useGenericQuery) => (queryArg, queryOptions) => {
  const orgId = useSelector((state) => state.auth?.activeOrgId);
  const genericQuery = useGenericQuery({ orgId, queryArg }, queryOptions);
  return genericQuery;
};

/**
 * This wrapper function adds the activeOrgId to the mutation.
 * @param {API Mutation} useGenericMutation The API mutation to wrap
 * @returns {API Mutation}
 */
export const wrapMutation = (useGenericMutation) => () => {
  const orgId = useSelector((state) => state.auth?.activeOrgId);
  const [genericMutation] = useGenericMutation();
  return [
    (queryArg, queryOptions) =>
      genericMutation({ orgId, queryArg }, queryOptions),
  ];
};
