import { AbComponentsProvider } from "@advocatebase/web-components";
import React from "react";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import AppLayout from "./components/AppLayout";
import { PageTitleProvider } from "./components/navigation/pageTitle";
import SplashScreen from "./components/pages/splash/SplashScreen";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store, { persistor } from "./store";
// import ServiceWorker from "./ServiceWorker";

createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <AbComponentsProvider
          config={{
            map: {
              accessToken: process.env.REACT_APP_MAPBOX_ACCESS_TOKEN ?? "",
            },
            featureToggles: {
              showChart: Boolean(process.env.REACT_APP_SHOW_CHART_VIEW),
              showKanban: Boolean(process.env.REACT_APP_SHOW_KANBAN_VIEW),
            },
          }}
          themeOptions={{
            components: {
              MuiTab: {
                styleOverrides: {
                  root: ({ ownerState }) => ({
                    textTransform: "none",
                    fontWeight: ownerState.selected ? "bold" : "normal",
                    fontSize: "1.2rem",
                    minHeight: "60px",
                  }),
                },
                variants: [
                  {
                    // @ts-ignore
                    props: { variant: "small" },
                    style: () => ({
                      fontSize: "1rem",
                    }),
                  },
                ],
              },
            },
          }}
        >
          <PageTitleProvider>
            {/* <ServiceWorker> */}
            <SplashScreen>
              <AppLayout />
            </SplashScreen>
            {/* </ServiceWorker> */}
          </PageTitleProvider>
        </AbComponentsProvider>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
