import _ from "lodash";
import { PageTree } from "../../services/pageApi";
import { ROUTE_SLUGS } from "./organisationRoutes";

export interface PageTreeRoute {
  id: string;
  path: string;
  options: {
    title: string;
    content: string;
    breadcrumbs: string[];
  };
  children: {
    slug: string;
    title: string;
  }[];
}

export const generatePageTreeRoutes = (
  page: PageTree,
  path = ROUTE_SLUGS.RESOURCES,
  breadcrumbs: string[] = []
): PageTreeRoute[] => {
  const treeRoutes: PageTreeRoute[] = [];

  treeRoutes.push({
    path,
    options: {
      title: page.title,
      content: page.content,
      breadcrumbs: [...breadcrumbs, page.title],
    },
    id: page.id,
    children: (page.children ?? []).map((childPage) => ({
      slug: childPage.slug,
      title: childPage.title,
    })),
  });

  for (let i = 0; i < (page.children ?? []).length; i++) {
    treeRoutes.push(
      ...generatePageTreeRoutes(
        page.children[i],
        _.trimEnd(`${path}/${page.children[i].slug}`, "/"),
        [...breadcrumbs, page.title]
      )
    );
  }

  return treeRoutes;
};
