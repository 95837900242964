import { useAbNavigator } from "@advocatebase/web-components";
import { Button, Paper, Typography } from "@mui/material";
import BasePage from "./BasePage";

interface DesktopOnlyPageProps {
  title: string;
  hideTitleBar?: boolean;
}

const NoMobilePage = ({ title, hideTitleBar }: DesktopOnlyPageProps) => {
  const nav = useAbNavigator();

  return (
    <BasePage title={title} hideTitleBar={hideTitleBar} scrollY>
      {/* @ts-ignore */}
      <Paper square variant="page-element-notop" sx={{ p: 2 }}>
        <Typography variant="h2" paragraph>
          Laptops and Desktops Only
        </Typography>
        <Typography variant="body1" paragraph>
          This page is not designed to be viewed on mobile. Please visit this
          page on a laptop or desktop computer instead.
        </Typography>
        <Button variant="contained" onClick={() => nav.back()}>
          Back
        </Button>
      </Paper>
    </BasePage>
  );
};

export default NoMobilePage;
