import { lazyRetry } from "@advocatebase/web-components";
import React from "react";
import { Navigate } from "react-router-dom";
import AutoChangeOrgPage from "../pages/login/AutoChangeOrgPage";

const DashboardPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "DashboardPage" */ "../pages/dashboard/DashboardPage"
      ),
    "DashboardPage"
  )
);
const NotFoundPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "NotFoundPage" */ "../pages/standard/NotFoundPage"
      ),
    "NotFoundPage"
  )
);

const NOT_FOUND_SLUG = "/notfound";

export const generateRoutes = ({ homePageSlug, loginPageSlug }) => [
  {
    path: "/",
    element: DashboardPage,
  },
  {
    // In case the location has not changed on login
    path: loginPageSlug,
    element: Navigate,
    options: { to: homePageSlug },
  },
  {
    // Note, there is a similar OrganisationSelectPage in profileRoutes.js
    path: "/o/:orgId",
    element: AutoChangeOrgPage,
    options: {
      notFoundPageSlug: NOT_FOUND_SLUG,
    },
  },
  // Keep /notfound reserved for the NotFoundPage. (NB - no need to add it explicitly here; if no such route is specified, it will default to the catch-all NotFoundPage)
  {
    path: "*",
    element: NotFoundPage,
    options: { homePageSlug },
  },
];
