import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AuthStatus } from "../../store/types";
import useAuth from "../../store/useAuth";

interface RequireAuthProps {
  children: React.ReactNode;
  loginPageSlug: string;
}

const RequireAuth = ({ children, loginPageSlug }: RequireAuthProps) => {
  const location = useLocation();
  const auth = useAuth();

  if (auth.status === AuthStatus.Pending) {
    return null;
  } else if (auth.status === AuthStatus.Unauthorised) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={loginPageSlug} state={{ from: location }} />;
  }

  return children;
};

export default RequireAuth;
