import React from "react";
import { Box, Breadcrumbs, Typography, Divider } from "@mui/material";
import BasePage from "./BasePage";
import {
  AbLink,
  AbScrollTopFab,
  useIsMobile,
} from "@advocatebase/web-components";
import CustomisedContentRenderer from "../../dynamicContent/CustomisedContentRenderer";

const MAX_BREADCRUMBS_MOBILE = 2;
const MAX_BREADCRUMBS_DESKTOP = 5;

const DynamicPage = ({ title, content, breadcrumbs }) => {
  const isMobile = useIsMobile();
  const ref = React.createRef();

  return (
    <BasePage
      title={title}
      scrollY
      ref={ref}
      sx={{
        pb: "55px", // so that the "scroll to top" FAB does not cover the content
      }}
    >
      <Box
        sx={{
          p: 2,
          maxWidth: (theme) => theme.dynamicPageMaxWidth,
        }}
      >
        {breadcrumbs.length > 1 && (
          <>
            <Breadcrumbs
              maxItems={
                isMobile ? MAX_BREADCRUMBS_MOBILE : MAX_BREADCRUMBS_DESKTOP
              }
              aria-label="breadcrumb"
              sx={{ mb: 2 }}
            >
              {breadcrumbs.map((crumb, i) =>
                i < breadcrumbs.length - 1 ? (
                  <Typography variant="body1" key={i}>
                    <AbLink
                      underline="hover"
                      color="inherit"
                      href={Array(Math.max(0, breadcrumbs.length - i - 1))
                        .fill("../")
                        .join("")}
                    >
                      {crumb}
                    </AbLink>
                  </Typography>
                ) : (
                  <Typography variant="body1" color="secondary" key={i}>
                    {crumb}
                  </Typography>
                )
              )}
            </Breadcrumbs>
            <Divider sx={{ mb: 2 }} />
          </>
        )}
        <CustomisedContentRenderer content={content} />
      </Box>
      <AbScrollTopFab containerRef={ref} />
    </BasePage>
  );
};

export default DynamicPage;
