import {
  AbDivider,
  useAbNavigator,
  useIsMobile,
} from "@advocatebase/web-components";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import CloseIcon from "@mui/icons-material/Close";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SecurityIcon from "@mui/icons-material/Security";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { ReactComponent as SecondaryWhiteLogo } from "../../assets/secondaryLogoWhite.svg";
import { useApiGetNotificationList } from "../../services/notificationsApi";
import { changeOrganisation, logout } from "../../store/authSlice";
import { AdvocateBaseRole } from "../../store/types";
import useAuth from "../../store/useAuth";
import NotificationsDialog from "./NotificationsDialog";
import { useGetPageTitle } from "./pageTitle";

const showOrgSelect = (tokenPayload) =>
  tokenPayload.organisations.length +
    1 * [AdvocateBaseRole.Admin].includes(tokenPayload.role) >
  1;

const CustomAppBar = ({
  profilePageSlug,
  securityPageSlug,
  settingsPageSlug,
  changeOrganisationPageSlug,
  drawerOpen,
  onDrawerOpen,
  onDrawerClose,
}) => {
  const dispatch = useDispatch();
  const nav = useAbNavigator();
  const location = useLocation();
  const auth = useAuth();
  const isMobile = useIsMobile();
  const pageTitle = useGetPageTitle();
  const unreadNotificationsCount = useApiGetNotificationList()?.filter(
    (noti) => !noti.hasBeenRead
  )?.length;

  const [notificationsAnchorEl, setNotificationsAnchorEl] =
    React.useState(null);
  const [accountAnchorEl, setAccountAnchorEl] = React.useState(null);
  const notificationsOpen = Boolean(notificationsAnchorEl);
  const accountOpen = Boolean(accountAnchorEl);
  const handleNotificationsClick = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };
  const handleAccountClick = (event) => {
    setAccountAnchorEl(event.currentTarget);
  };
  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };
  const handleAccountClose = () => {
    setAccountAnchorEl(null);
  };

  const avatarSrc = "";

  const leftActions = React.useMemo(
    () => ({
      closeDrawer: { icon: <CloseIcon />, onClick: onDrawerClose },
      openDrawer: { icon: <MenuIcon />, onClick: onDrawerOpen },
      navUp: { icon: <ArrowBackIcon />, onClick: () => nav.up() },
    }),
    [onDrawerClose, onDrawerOpen, nav]
  );

  const activeLeftAction = React.useMemo(() => {
    if (!isMobile) return null;
    if (drawerOpen) return leftActions.closeDrawer;
    if (location.pathname.split("/").length > 2) return leftActions.navUp;
    return leftActions.openDrawer;
  }, [drawerOpen, location.pathname, leftActions, isMobile]);

  return (
    <AppBar
      position="relative"
      sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
    >
      <Toolbar variant={isMobile ? "regular" : "dense"}>
        {activeLeftAction && (
          <IconButton
            color="inherit"
            onClick={activeLeftAction.onClick}
            sx={{ mr: 1, ml: -1.5 }}
          >
            {activeLeftAction.icon}
          </IconButton>
        )}
        {!isMobile && [
          <Box
            sx={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "center",
              py: 0.5,
              position: "absolute",
              left: 0,
              right: 0,
            }}
            key="logo"
          >
            <SecondaryWhiteLogo height="40px" />
          </Box>,
          <Box sx={{ flexGrow: 1 }} key="spacer" />,
        ]}
        {isMobile && (
          <Typography
            variant="h4"
            component="div"
            sx={{
              flexGrow: 1,
              textOverflow: "ellipsis",
              textWrap: "nowrap",
              overflow: "hidden",
            }}
          >
            {pageTitle}
          </Typography>
        )}
        <Tooltip title="Notifications">
          <IconButton
            onClick={handleNotificationsClick}
            color="inherit"
            sx={{ ml: 1 }}
          >
            <Badge
              badgeContent={unreadNotificationsCount}
              color="error"
              slotProps={{ badge: { sx: { right: 0, top: 5 } } }}
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleAccountClick}
            color="inherit"
            sx={{ mr: -1.5 }}
            aria-controls={accountOpen ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={accountOpen ? "true" : undefined}
          >
            {!!avatarSrc ? (
              <Avatar sx={{ width: 24, height: 24 }} />
            ) : (
              <AccountCircleIcon />
            )}
          </IconButton>
        </Tooltip>
      </Toolbar>
      <NotificationsDialog
        onClose={handleNotificationsClose}
        open={notificationsOpen}
        anchorEl={notificationsAnchorEl}
      />
      <Menu
        anchorEl={accountAnchorEl}
        id="account-menu"
        open={accountOpen}
        onClose={handleAccountClose}
        onClick={handleAccountClose}
        MenuListProps={{ sx: { pr: 0 } }}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 0.5,
              ...(isMobile && { right: "8px" }),
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 10.7,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={() => nav.to(profilePageSlug)}>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={() => nav.to(securityPageSlug)}>
          <ListItemIcon>
            <SecurityIcon fontSize="small" />
          </ListItemIcon>
          Security
        </MenuItem>
        <MenuItem onClick={() => nav.to(settingsPageSlug)}>
          <ListItemIcon>
            <SettingsIcon fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        {showOrgSelect(auth.tokenPayload) && (
          <MenuItem
            onClick={() => {
              dispatch(changeOrganisation());
              nav.to(changeOrganisationPageSlug);
            }}
          >
            <ListItemIcon>
              <ChangeCircleIcon fontSize="small" />
            </ListItemIcon>
            Change Organisation
          </MenuItem>
        )}
        <AbDivider />
        <MenuItem onClick={() => dispatch(logout())}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  );
};

export default CustomAppBar;
