import { AbLink, ContentRendererType } from "@advocatebase/web-components";
import { Typography } from "@mui/material";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useApiGetPageTree } from "../../services/pageApi";
import { generatePageTreeRoutes } from "../routes/generatePageTreeRoutes";

const DirectoryRenderer: ContentRendererType = () => {
  const pageTree = useApiGetPageTree();
  const routes = pageTree ? generatePageTreeRoutes(pageTree) : null;
  const location = useLocation();

  const currentRoute = routes?.find(
    (route) => route.path === _.trimEnd(location.pathname, "/")
  );

  return currentRoute && currentRoute.children.length > 0 ? (
    <ul style={{ marginTop: 0 }}>
      {currentRoute.children.map((route, i) => (
        <li key={i}>
          <Typography variant="body1">
            <AbLink href={route.slug}>{route.title}</AbLink>
          </Typography>
        </li>
      ))}
    </ul>
  ) : null;
};

export default DirectoryRenderer;
