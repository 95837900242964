import { logger } from "@advocatebase/toolbox";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Socket, io } from "socket.io-client";
import { logout } from "../store/authSlice";
import baseApi from "./baseApi";
import { API_BASE_URL } from "./consts";
import handleIntegrationSocket from "./integrationSocket";
import { SocketHandler } from "./types";

interface SocketData {
  socketKey: string;
}

// Socket handlers to apply to the socket. (Note - these will usually contain socket event listeners.)
const handleSockets: SocketHandler[] = [handleIntegrationSocket];

export const useSocketListeners = () => {
  const token: string | undefined = useSelector(
    (state: any) => state.auth.token
  );
  const dispatch = useDispatch();
  const orgId = useSelector((state) => (state as any).auth?.activeOrgId);
  const socket = React.useRef<Socket>();
  const socketData = React.useRef<Partial<SocketData>>({});

  React.useEffect(() => {
    socket.current?.disconnect(); // disconnect if orgId or token (or dispatch) changes, before reconnecting
    if (token && orgId) {
      socket.current = io(API_BASE_URL, {
        extraHeaders: { Authorization: `Bearer ${token}` },
      });
      socket.current.on("connect", () => {
        logger.success("WebSocket connected");
      });
      socket.current.on("connect_error", (err: Error) => {
        logger.debug("WebSocket connection error", err);
      });
      socket.current.on("disconnect", (reason, description) => {
        logger.debug(`WebSocket disconnected - ${reason}: ${description}`);
      });
      socket.current.on("socketKey", (socketKey) => {
        socketData.current.socketKey = socketKey;
      });
      socket.current.on("serverError", (payload) => {
        logger.debug(`WebSocket error - ${payload.body}`);
        if (payload.body?.error?.tokenExpired) {
          dispatch(logout());
        }
      });
      socket.current.on("notificationEvent", (payload) => {
        if (payload.body.id) {
          // Redux Toolkit's recommended approach is to request a cache refresh rather than mutate the cache with the new object [https://redux-toolkit.js.org/rtk-query/usage/manual-cache-updates#overview]
          dispatch(baseApi.util.invalidateTags(["Notifications"]));
          // Note - do not trigger a push notification here. Push notifications are sent by the server directly to the service worker, which runs independently of this app (and can keep on running even when this app is closed).
        }

        // Example socket emit:
        // socket.current?.emit("helloEvent", {
        //   headers: {
        //     "x-socketkey": socketData.current.socketKey,
        //   },
        //   body: {
        //     message: "Hello World",
        //   },
        // });
      });
      // socket.current.onAny((...args) => logger.debug(args));

      // Apply handlers to the socket. (Note - these will usually contain socket event listeners.)
      for (let i = 0; i < handleSockets.length; i++) {
        handleSockets[i](socket.current, dispatch);
      }
    }
  }, [token, dispatch, orgId]);
};
