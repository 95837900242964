import {
  updateIntegrationScanPercentage,
  updateIntegrationSyncStatus,
} from "../store/websocketReducer/integrationsSlice";
import { SocketHandler } from "./types";

const handleIntegrationSocket: SocketHandler = (socket, dispatch) => {
  socket.on("integrationSyncStatus", (payload) => {
    if (payload.body.id) {
      dispatch(updateIntegrationSyncStatus(payload.body));
    }
  });
  socket.on("integrationScanPercentage", (payload) => {
    if (payload.body.id) {
      dispatch(updateIntegrationScanPercentage(payload.body));
    }
  });
};

export default handleIntegrationSocket;
