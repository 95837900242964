import React from "react";
import { ContextError } from "@advocatebase/web-components";

interface PageTitleContextType {
  pageTitle: string | null;
  setPageTitle: React.Dispatch<React.SetStateAction<string | null>>;
}

const PageTitleContext = React.createContext<PageTitleContextType | undefined>(
  undefined
);

export const PageTitleProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [pageTitle, setPageTitle] = React.useState<string | null>(null);

  return (
    <PageTitleContext.Provider value={{ pageTitle, setPageTitle }}>
      {children}
    </PageTitleContext.Provider>
  );
};

export const useGetPageTitle = () => {
  const context = React.useContext(PageTitleContext);
  if (!context) throw new ContextError("useGetPageTitle", "PageTitleProvider");
  return context.pageTitle;
};

export const useSetPageTitle = (title: string) => {
  const context = React.useContext(PageTitleContext);
  if (!context) throw new ContextError("useSetPageTitle", "PageTitleProvider");
  context.setPageTitle(title);
};
