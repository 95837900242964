import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { authHeader, logout } from "../store/authSlice";
import { API_BASE_URL } from "./consts";

// Custom base query to force logout if the token expires at any point.
const customBaseQuery =
  (options: any) => async (args: any, api: any, extraOptions: any) => {
    const result = await fetchBaseQuery(options)(args, api, extraOptions);
    if ((result?.error?.data as any)?.tokenExpired) {
      api.dispatch(logout());
    }
    return result;
  };

const ALL_TAGS = [
  "Teams",
  "TeamsDetailed",
  "TeamsLite",
  "TeamsMeta",
  "People",
  "PeopleMeta",
  "Events",
  "EventsMeta",
  "Users",
  "PeopleLite",
  "PageTree",
  "Pages",
  "Organisations",
  "OrganisationsLite",
  "Profile",
  "Quals",
  "TrainingSessionDefinitions",
  "TrainingSessionInstances",
  "ConsentDefinitions",
  "ConsentRecords",
  "OptionLabels",
  "Notifications",
  "NotificationTriggers",
  "Integrations",
  "IntegrationsMeta",
];

// Initialize an empty api service. Endpoints will be injected elsewhere as needed.
const baseApi = createApi({
  baseQuery: customBaseQuery({
    baseUrl: API_BASE_URL,
    prepareHeaders: (headers: Headers, { getState }: { getState: () => any }) =>
      authHeader(headers, getState()),
  }),
  tagTypes: ALL_TAGS,
  endpoints: () => ({}),
});

export default baseApi;
