import { useAppSelector } from ".";
import {
  AdvocateBaseRole,
  AuthState,
  AuthStatus,
  OrganisationRole,
  TeamRole,
  ViewMode,
} from "./types";

export type UseAuthReturnValue = AuthState & {
  showAdvocateBaseAdminPortal?: boolean;

  // ViewMode.ORGANISATION
  showTeams?: boolean;
  showPeople?: boolean;
  showEvents?: boolean;
  showCustomPageEditor?: boolean;
  showUsers?: boolean;
  showQuals?: boolean;
  showEditableQuals?: boolean;
  showTrainingSessions?: boolean;
  showTrainingSessionInstances?: boolean;
  showEditableTrainingSessionInstances?: boolean;
  showTrainingSessionDefinitions?: boolean;
  showEditableTrainingSessionDefinitions?: boolean;
  showAdminSection?: boolean;
  showConsentRecords?: boolean;
  showConsentDefinitions?: boolean;
  showConsents?: boolean;
  showIntegrations?: boolean;

  // ViewMode.ADVOCATEBASE
  showOrgs?: boolean;
  showAdvocateBaseAdmin?: boolean;

  // Either view mode
  showEditablePeople?: boolean;
  showEditableEvents?: boolean;
  showDeletableEvents?: boolean;
  showEditableTeams?: boolean;
  showDeletablePeople?: boolean;
  showImportPeople?: boolean;
};

export default function useAuth(): UseAuthReturnValue {
  const auth = useAppSelector((state) => state.auth);

  if (auth.status !== AuthStatus.Authorised) {
    return auth;
  }

  const activeOrganisation = auth.tokenPayload.organisations.find(
    (org) => org.id === auth.activeOrgId
  );

  const isAdvocateBaseManager = [AdvocateBaseRole.Admin].includes(
    auth.tokenPayload?.role
  );

  const isOrgAdmin =
    activeOrganisation?.role !== undefined &&
    [OrganisationRole.Admin].includes(activeOrganisation?.role);

  const isOrgManager =
    activeOrganisation?.role !== undefined &&
    [OrganisationRole.Admin, OrganisationRole.Manager].includes(
      activeOrganisation?.role
    );

  const isTeamManager =
    activeOrganisation?.teams?.some((team) =>
      [TeamRole.Admin, TeamRole.Manager].includes(team.role)
    ) ?? false;

  const isViewOrgMode = auth.viewMode === ViewMode.Organisation;
  const isViewABMode = auth.viewMode === ViewMode.Advocatebase;

  const showCustomPageEditor = isViewOrgMode && isOrgManager;
  const showUsers = isViewOrgMode && isOrgAdmin;
  const showQuals = isViewOrgMode && isOrgAdmin;
  const showEditableQuals = isViewOrgMode && isOrgAdmin;

  const showTrainingSessionInstances = isAdvocateBaseManager || isOrgManager;
  const showEditableTrainingSessionInstances =
    isAdvocateBaseManager || isOrgManager;
  const showTrainingSessionDefinitions = isAdvocateBaseManager || isOrgManager;
  const showEditableTrainingSessionDefinitions =
    isAdvocateBaseManager || isOrgManager;
  // const showIntegrations = isViewOrgMode && isOrgAdmin;
  const showIntegrations = isViewOrgMode && isAdvocateBaseManager; // TEMP - TODO - swap this for the above line (commented out), once the integrations feature is ready.

  const showConsentRecords = isAdvocateBaseManager || isOrgManager;
  const showConsentDefinitions = isAdvocateBaseManager || isOrgManager;

  return {
    ...auth,

    // Change org screen
    showAdvocateBaseAdminPortal: isAdvocateBaseManager,

    // ViewMode.ORGANISATION
    showTeams: isViewOrgMode && isOrgManager,
    showPeople: isViewOrgMode && (isOrgManager || isTeamManager),
    showEvents:
      isViewOrgMode &&
      (isOrgManager ||
        (isTeamManager && !!process.env.REACT_APP_SHOW_EVENTS_FOR_NON_STAFF)),
    showCustomPageEditor,
    showUsers,
    showQuals,
    showEditableQuals,
    showTrainingSessionInstances,
    showEditableTrainingSessionInstances,
    showTrainingSessionDefinitions,
    showEditableTrainingSessionDefinitions,
    showTrainingSessions:
      showTrainingSessionInstances || showTrainingSessionDefinitions,

    showIntegrations,
    showAdminSection:
      showCustomPageEditor || showUsers || showQuals || showIntegrations,
    showConsentRecords,
    showConsentDefinitions,
    showConsents: showConsentRecords || showConsentDefinitions,

    // ViewMode.ADVOCATEBASE
    showOrgs: isViewABMode && isAdvocateBaseManager,
    showAdvocateBaseAdmin: isViewABMode && isAdvocateBaseManager,

    // Either view mode
    showEditablePeople:
      isAdvocateBaseManager ||
      isOrgManager ||
      !!process.env.REACT_APP_SHOW_EDIABLE_PEOPLE_FOR_NON_STAFF,
    showEditableEvents: isAdvocateBaseManager || isOrgManager || isTeamManager,
    showDeletableEvents: isAdvocateBaseManager || isOrgManager,
    showEditableTeams: isAdvocateBaseManager || isOrgManager,
    showDeletablePeople: isAdvocateBaseManager || isOrgManager,
    showImportPeople:
      isAdvocateBaseManager ||
      (isOrgManager && !!process.env.REACT_APP_SHOW_BULK_IMPORT),
  };
}
