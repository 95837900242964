import {
  AbToolbar,
  AbToolbarAction,
  colorBackgroundMixin,
  scrollbarStylesMixin,
  useIsMobile,
} from "@advocatebase/web-components";
import { Box, CircularProgress, SxProps, Theme } from "@mui/material";
import React from "react";
import { useSetPageTitle } from "../../navigation/pageTitle";
import NoConnectionPage from "./NoConnectionPage";
import NoMobilePage from "./NoMobilePage";
import NotFoundPage from "./NotFoundPage";

const CommonPageContainer = ({
  colorBackground,
  sx,
  children,
}: {
  colorBackground: boolean;
  children: React.ReactNode;
  sx: SxProps;
}) => {
  const isMobile = useIsMobile();

  return (
    <Box
      data-id="page-container"
      sx={{
        height: "100%",
        display: "flex",
        ...(colorBackground && !isMobile && colorBackgroundMixin),
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

type BasePageProps = {
  actions?: AbToolbarAction[];
  scrollY?: boolean;
  colorBackground?: boolean;
  onScroll?: () => void;
  loading?: boolean;
  error?: boolean;
  noMobile?: boolean;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
} & (
  | { title?: string; hideTitleBar: true }
  | { title: string; hideTitleBar?: boolean }
);

const BasePage = React.forwardRef(
  (
    {
      title,
      actions,
      hideTitleBar = false,
      scrollY = false,
      colorBackground = false,
      onScroll = () => null,
      loading = false,
      noMobile = false,
      error = false,
      sx = {},
      children,
    }: BasePageProps,
    ref
  ) => {
    useSetPageTitle(title ?? "");
    const isMobile = useIsMobile();

    // TODO: Resolve this. NoMobilePage, NotFoundPage and NoConnectionPage all recursively call BasePage. Is this an antipattern?

    if (noMobile && isMobile) {
      return <NoMobilePage title={title ?? ""} hideTitleBar={hideTitleBar} />;
    }

    if (error) {
      if (navigator.onLine) {
        return <NotFoundPage />;
      } else {
        return <NoConnectionPage />;
      }
    }

    if (loading) {
      return (
        <CommonPageContainer
          colorBackground={colorBackground}
          sx={{
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="inherit" />
        </CommonPageContainer>
      );
    }

    return (
      <CommonPageContainer
        colorBackground={colorBackground}
        sx={{
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        {!hideTitleBar && (
          <AbToolbar
            data-id="page-title-container"
            title={title}
            actions={actions}
          />
        )}
        <Box
          ref={ref}
          onScroll={onScroll}
          sx={{
            flexGrow: 1,
            overflow: "hidden",
            ...(scrollY && {
              height: "100%",
              overflowY: "auto",
              ...scrollbarStylesMixin(),
            }),
            ...(sx as SxProps),
          }}
          data-id="page-content-container"
        >
          {children}
        </Box>
      </CommonPageContainer>
    );
  }
);

export default BasePage;
