import React from "react";
import Box from "@mui/material/Box";
import { SxProps, Theme } from "@mui/material";

interface FixedWrapperProps {
  children: React.ReactNode;
  onChangeHeight: (height: number) => void;
  sx?: SxProps<Theme>;
}

const FixedWrapper = ({ onChangeHeight, children, sx }: FixedWrapperProps) => {
  const ref = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (ref.current?.clientHeight) {
      onChangeHeight(ref.current.clientHeight);
    }
  }, [ref.current?.clientHeight, onChangeHeight]);

  return (
    <Box
      ref={ref}
      sx={{
        width: "100%",
        position: "fixed",
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};

export default FixedWrapper;
