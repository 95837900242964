import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { IntegrationSyncStatus } from "../../services/integrationApi";
import { State, WebSocketIntegrationsState } from "../types";

const integrationsSlice = createSlice({
  name: "integrations",
  initialState: {} as Record<string, WebSocketIntegrationsState>,
  reducers: {
    updateIntegrationSyncStatus: (
      state,
      action: PayloadAction<{ id: string; syncStatus: IntegrationSyncStatus }>
    ) => {
      // Note: Redux toolkit allows mutations of reducer state.
      state[action.payload.id] = {
        ...state[action.payload.id],
        syncStatus: action.payload.syncStatus,
      };
    },
    updateIntegrationScanPercentage: (
      state,
      action: PayloadAction<{ id: string; scanPercentage: number }>
    ) => {
      // Note: Redux toolkit allows mutations of reducer state.
      state[action.payload.id] = {
        ...state[action.payload.id],
        scanPercentage: action.payload.scanPercentage,
      };
    },
  },
  extraReducers: (builder) => {
    // Ensure websocket state is reset if a GET update has been retrieved from the REST API.
    builder.addMatcher(
      (action) =>
        action?.type?.endsWith("/fulfilled") &&
        action?.meta?.arg?.endpointName === "getIntegration",
      (state, action) => {
        state[action.payload.id] = {
          ...state[action.payload.id],
          syncStatus: action.payload.syncStatus,
        };
      }
    );
  },
});

export const useWebsocketIntegrations = () =>
  useSelector<State>((state) => state.websocket.integrations) as Record<
    string,
    WebSocketIntegrationsState
  >;

export const { updateIntegrationSyncStatus, updateIntegrationScanPercentage } =
  integrationsSlice.actions;
export default integrationsSlice.reducer;
