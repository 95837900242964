import { lazyRetry } from "@advocatebase/web-components";
import { Box, CircularProgress } from "@mui/material";
import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import RequireNotAuth from "../navigation/RequireNotAuth";

const LoginPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(/* webpackChunkName: "LoginPage" */ "../pages/login/LoginPage"),
    "LoginPage"
  )
);
const ForgotPasswordPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ForgotPasswordPage" */ "../pages/login/ForgotPasswordPage"
      ),
    "ForgotPasswordPage"
  )
);
const ResetPasswordPage = React.lazy(() =>
  lazyRetry(
    () =>
      import(
        /* webpackChunkName: "ResetPasswordPage" */ "../pages/login/ResetPasswordPage"
      ),
    "ResetPasswordPage"
  )
);

export const NOT_AUTH_ROUTE_PATHS = {
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgotpassword",
  RESET_PASSWORD: "/resetpassword",
};

const routes = [
  {
    path: NOT_AUTH_ROUTE_PATHS.LOGIN,
    element: LoginPage,
    options: {
      forgotPasswordPageSlug: NOT_AUTH_ROUTE_PATHS.FORGOT_PASSWORD,
    },
  },
  {
    path: NOT_AUTH_ROUTE_PATHS.FORGOT_PASSWORD,
    element: ForgotPasswordPage,
    options: {
      loginPageSlug: NOT_AUTH_ROUTE_PATHS.LOGIN,
    },
  },
  {
    path: NOT_AUTH_ROUTE_PATHS.RESET_PASSWORD,
    element: ResetPasswordPage,
    options: {
      forgotPasswordPageSlug: NOT_AUTH_ROUTE_PATHS.FORGOT_PASSWORD,
      loginPageSlug: NOT_AUTH_ROUTE_PATHS.LOGIN,
    },
  },
  {
    path: "*",
    element: Navigate,
    options: { to: NOT_AUTH_ROUTE_PATHS.LOGIN },
  },
];

const NotAuthRouter = () => {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      }
    >
      <Routes>
        {routes.map((route) => (
          <Route
            path={route.path}
            element={
              <RequireNotAuth redirectTo={NOT_AUTH_ROUTE_PATHS.LOGIN}>
                <route.element {...route.options} />
              </RequireNotAuth>
            }
            key={route.path}
          />
        ))}
      </Routes>
    </Suspense>
  );
};

export default NotAuthRouter;
