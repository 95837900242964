import GroupsIcon from "@mui/icons-material/Groups";
import PersonIcon from "@mui/icons-material/Person";
import EventNoteIcon from "@mui/icons-material/EventNote";
import HomeIcon from "@mui/icons-material/Home";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import useAuth from "../../store/useAuth";
import useCustomLabels from "./useCustomLabels";

const getAllLinks = (auth, customLabels) =>
  [
    auth.showAdvocateBaseAdmin
      ? {
          label: "Home",
          icon: <HomeIcon />,
          to: "/home",
        }
      : [
          {
            label: "Dashboard",
            icon: <HomeIcon />,
            to: "/",
          },
          {
            label: "Resources",
            icon: <MenuBookIcon />,
            description: `Resources for you and your ${customLabels.teams.singular.toLowerCase()}.`,
            to: "/resources",
          },
        ],
    auth.showTeams && {
      label: customLabels.teams.plural,
      icon: <GroupsIcon />,
      description: `Coordinate all ${customLabels.teams.plural.toLowerCase()}.`,
      to: "/teams",
    },
    auth.showPeople && {
      label: "People",
      icon: <PersonIcon />,
      to: "/people",
      description: `Coordinate your people.`,
    },
    auth.showEvents && {
      label: customLabels.events.plural,
      icon: <EventNoteIcon />,
      description: `Coordinate your ${customLabels.events.plural.toLowerCase()}.`,
      to: "/events",
    },
    auth.showOrgs && {
      label: "Organisations",
      icon: <HomeWorkIcon />,
      to: "/organisations",
    },
    auth.showAdvocateBaseAdmin && {
      label: "Users",
      icon: <AdminPanelSettingsIcon />,
      to: "/users",
    },
  ]
    .flat()
    .filter((link) => !!link);

export default function useNavLinks() {
  const auth = useAuth();
  const customLabels = useCustomLabels();
  const allLinks = customLabels && getAllLinks(auth, customLabels);
  return allLinks;
}
