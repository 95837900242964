import {
  colorBackgroundMixin,
  hasBeenLazyRefreshed,
} from "@advocatebase/web-components";
import { Box, Fade } from "@mui/material";
import React from "react";
import { ReactComponent as PrimaryLogo } from "../../../assets/primaryLogo.svg";

interface SplashScreenProps {
  children: React.ReactNode;
}

const SPLASH_SCREEN_WAIT_DURATION = 1000;
const SPLASH_SCREEN_FADE_DURATION = 500;

const SplashScreen = ({ children }: SplashScreenProps) => {
  const [loadSplash, setLoadSplash] = React.useState(true);
  const [isVisible, setIsVisible] = React.useState(true);

  React.useEffect(() => {
    if (hasBeenLazyRefreshed()) {
      setIsVisible(false);
      setLoadSplash(false);
    } else {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, SPLASH_SCREEN_WAIT_DURATION);

      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <Box sx={{ position: "relative", overflow: "hidden" }}>
      {loadSplash && (
        <Fade
          in={isVisible}
          timeout={SPLASH_SCREEN_FADE_DURATION}
          onExited={() => setLoadSplash(false)}
        >
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              height: "100vh",
              width: "100vw",
              zIndex: 1401,
              ...colorBackgroundMixin,
            }}
          >
            <PrimaryLogo width="300px" />
          </Box>
        </Fade>
      )}
      {isVisible && (
        <Box
          sx={{
            height: "100vh",
            width: "100vw",
            background: "white",
            position: "absolute",
            zIndex: 1400,
          }}
        />
      )}
      {children}
    </Box>
  );
};

export default SplashScreen;
