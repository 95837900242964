import React from "react";
import {
  CustomLabels,
  useGetCustomLabelsNoCache,
} from "../../services/optionsApi";

const defaultLabels: CustomLabels = {
  teams: {
    singular: "Team",
    plural: "Teams",
    maybePlural: "Team(s)",
  },
  events: {
    singular: "Event",
    plural: "Events",
    maybePlural: "Event(s)",
  },
  quals: {
    singular: "Qualification",
    plural: "Qualifications",
  },
};

let customLabelCache: CustomLabels | null = null;

export default function useCustomLabels(defaultData = false) {
  const customLabels = useGetCustomLabelsNoCache();

  React.useEffect(() => {
    if (customLabels) customLabelCache = customLabels;
  }, [customLabels]);

  return defaultData
    ? defaultLabels
    : !!customLabelCache
    ? customLabelCache
    : customLabels;
}
