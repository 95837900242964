import {
  AbContextMenu,
  useAbNavigator,
  useIsMobile,
} from "@advocatebase/web-components";
import { CardHeader, CardActionArea, Typography, Card } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import DraftsIcon from "@mui/icons-material/Drafts";

// This is used as an aid to help size the virtualised list (DataCardList)
const PLACEHOLDER = {
  title: ".",
  text: ".",
};

interface NotificationCardProps {
  blankPlaceholder?: boolean;
  title?: string;
  text?: string;
  href?: string;
  hasBeenRead?: boolean;
  onClick?: () => void;
  markAsRead?: (hasBeenRead: boolean) => void;
}

const NotificationCard = ({
  blankPlaceholder = false,
  title = ".",
  text = "Much testing. Here is a notification about something important.Much testing. Here is a notification about something important.Much testing. Here is a notification about something important.Much testing. Here is a notification about something important.",
  href = "",
  hasBeenRead = false,
  onClick,
  markAsRead,
}: NotificationCardProps) => {
  const isMobile = useIsMobile();
  const nav = useAbNavigator();

  return (
    <AbContextMenu
      actions={[
        {
          label: hasBeenRead ? "Mark as unread" : "Mark as read",
          icon: hasBeenRead ? <MarkunreadIcon /> : <DraftsIcon />,
          onClick: () => markAsRead?.(!hasBeenRead),
        },
      ]}
    >
      <Card
        square
        sx={{
          ...(hasBeenRead && {
            backgroundColor: (theme) => theme.palette.grey[100],
          }),
        }}
      >
        <CardActionArea
          sx={{ height: "5.5rem", position: "relative" }}
          href={!isMobile ? href : ""}
          onClick={(e) => {
            e.preventDefault();
            nav.to(href);
            onClick?.();
          }}
        >
          {!hasBeenRead && (
            <FiberManualRecordIcon
              sx={{ position: "absolute", top: "2rem", right: "4px" }}
              color="error"
            />
          )}
          <CardHeader
            title={
              <Typography
                variant="body1"
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 1,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontWeight: hasBeenRead ? "normal" : "bold",
                }}
              >
                {blankPlaceholder ? PLACEHOLDER.title : title}
              </Typography>
            }
            subheader={
              <Typography
                variant="body1"
                sx={{
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {blankPlaceholder ? PLACEHOLDER.text : text}
              </Typography>
            }
            disableTypography
            sx={{ py: 1, mr: 1 }}
          />
        </CardActionArea>
      </Card>
    </AbContextMenu>
  );
};

export default NotificationCard;
